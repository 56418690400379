.header {
    display: flex;
    background: inherit; /* Inherit background from parent */
    align-items: center;
    justify-content: space-between;
    padding: 20px; /* Consolidated padding */
    font-family: 'Arial', sans-serif;
}
  
.logo {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: bold;
}
  
/* Responsive Design */
  
@media (max-width: 768px) {
    .header {
        justify-content: space-between;
    }
}
  
@media (max-width: 480px) {
    .logo {
        font-size: 1.2rem;
    }
}