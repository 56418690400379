/* Base Styles */
body {
  font-family: 'Arial', sans-serif;
  background: linear-gradient(165deg, #000000 25%, #8e2de2 80%, #000000 95%);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  margin: 0;
  padding: 0;
  color: #ddd;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  max-width: 800px;
  margin: 2em auto;
  padding: 0 1em;
}

/* Header Styles */
header {
  background: rgba(30, 30, 30, 0.9);
  backdrop-filter: blur(10px);
  padding: 1em 0;
}

header nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

header .logo {
  font-size: 1.5em;
  font-weight: bold;
  color: #fff;
}

/* Hero Section Styles */
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  padding: 20px;
  position: relative;
  overflow: hidden;
  padding-top: 40px;
  margin-bottom: 2em;
}

.main-heading {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
}

.subtext {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 30px;
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
  max-width: 800px;
}

/* Button Styles */
button {
  background-color: #007BFF;
  color: #fff;
  padding: 0.75em 1.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.cta-button-hero {
  background-color: #793cea;
  color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 12px 30px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-family: 'Roboto', sans-serif;
  height: 50px;
}

.cta-button-hero:hover {
  background-color: #793cea;
  transform: scale(1.05);
}

/* Section Styles */
.upload-section,
.sneak-peek,
.payment {
  background: rgba(40, 40, 40, 0.9);
  backdrop-filter: blur(10px);
  padding: 2em;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upload-section h2,
.sneak-peek h2,
.payment h2 {
  font-size: 1.8em;
  margin-bottom: 1em;
  color: #fff;
}

.upload-section p,
.sneak-peek p,
.payment p {
  font-size: 1.2em;
  margin-bottom: 1em;
  color: #bbb;
}

/* Footer Styles */
footer {
  text-align: center;
  padding: 1em 0;
  background: inherit;
  backdrop-filter: blur(10px);
}

footer p {
  margin: 0;
  color: #aaa;
}

/* Form Styles */
input[type="file"] {
  background-color: #333;
  color: #fff;
  padding: 0.5em;
  border: 1px solid #555;
  border-radius: 5px;
  margin-bottom: 1em;
}

input[type="file"]::-webkit-file-upload-button {
  background-color: #007BFF;
  color: #fff;
  padding: 0.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

input[type="file"]::-webkit-file-upload-button:hover {
  background-color: #0056b3;
}

/* Testimonials Styles */
.testimonials {
  padding: 4rem 2rem;
  background: linear-gradient(165deg, rgba(0, 0, 0, 0.8) 25%, rgba(142, 45, 226, 0.8) 80%);
  margin-top: 2em;
}

.testimonials-title {
  text-align: center;
  color: #ffffff;
  font-size: 2.5rem;
  margin-bottom: 3rem;
  font-weight: bold;
}

.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.testimonial-card {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.testimonial-text {
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  flex-grow: 1;
}

.testimonial-author {
  color: #d1c4e9;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: right;
  margin-top: auto;
}

/* Updated Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: linear-gradient(135deg, #000000, #8e2de2);
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 500px;
  color: #ffffff;
  position: relative;
  overflow: hidden;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  color: #ffffff;
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.close-button:hover {
  transform: rotate(90deg);
}

.modal-content h2 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.modal-content p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
}

.file-input-label {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
}

.file-input-label:hover {
  background-color: #45a049;
}

.file-input-label.file-selected {
  background-color: #45a049;
}

.file-input-label input[type="file"] {
  display: none;
}

.modal-button {
  background-color: #d1c4e9;
  color: #333;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;
  margin-top: 1rem;
}

.modal-button:hover {
  background-color: #b39ddb;
  transform: translateY(-2px);
}

.modal-button:disabled {
  background-color: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
}

.payment-button {
  background-color: #4CAF50;
  color: white;
}

.payment-button:hover {
  background-color: #45a049;
}

.card-element-container {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.loading-container {
  text-align: center;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #ffffff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Analysis Result Styles */
#analysis-result {
  background: rgba(40, 40, 40, 0.9);
  backdrop-filter: blur(10px);
  padding: 2em;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  margin: 2em auto;
  max-width: 800px;
}

#analysis-result h1 {
  color: #d1c4e9;
  margin-bottom: 1em;
}

#analysis-result h2 {
  color: #fff;
  border-bottom: 2px solid #444;
  padding-bottom: 10px;
  margin-top: 30px;
}

#analysis-message {
  color: #ddd;
}

#analysis-message ul {
  list-style-type: none;
  padding-left: 0;
}

#analysis-message li {
  margin-bottom: 10px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

#analysis-message strong {
  color: #d1c4e9;
}

#analysis-message .fun-report p {
  font-style: italic;
  color: #bbb;
}

/* Loading Styles */
#loading {
  text-align: center;
  margin: 20px 0;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

/* Payment Section Styles */
.payment-section {
  background: rgba(40, 40, 40, 0.9);
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

/* Arrow Animation */
@keyframes moveArrow {
  0% { transform: translateX(0); }
  50% { transform: translateX(5px); }
  100% { transform: translateX(0); }
}

.arrow {
  display: inline-block;
  animation: moveArrow 1s infinite;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .main-heading {
    font-size: 2.5rem;
  }

  .subtext {
    font-size: 1.1rem;
  }

  .cta-button-hero {
    padding: 10px 20px;
    font-size: 0.95rem;
  }
}

@media (max-width: 768px) {
  .main-heading {
    font-size: 2rem;
  }

  .subtext {
    font-size: 1rem;
  }

  .cta-button-hero {
    padding: 9px 18px;
    font-size: 0.9rem;
  }

  .testimonials-title {
    font-size: 2rem;
  }

  .testimonial-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .main-heading {
    font-size: 1.8rem;
  }

  .subtext {
    font-size: 0.95rem;
  }

  .cta-button-hero {
    padding: 8px 16px;
    font-size: 0.85rem;
  }
}

/* Results Page Styles */
#analysis-result {
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(142, 45, 226, 0.8));
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  color: #ffffff;
  margin-top: 2rem;
}

#analysis-result h1 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
  color: #d1c4e9;
}

.result-section {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.result-section h2 {
  color: #d1c4e9;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.result-section h3 {
  color: #d1c4e9;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.result-section h4 {
  color: #d1c4e9;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.result-section ul {
  list-style-type: none;
  padding-left: 0;
}

.result-section li {
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
}

.result-section strong {
  color: #d1c4e9;
}

.sub-section {
  margin-top: 1.5rem;
}

.fun-report p {
  font-style: italic;
  color: #bbb;
}

@media (max-width: 768px) {
  #analysis-result {
    padding: 1.5rem;
  }

  #analysis-result h1 {
    font-size: 2rem;
  }

  .result-section {
    padding: 1rem;
  }
}

.ranking-category {
  margin-top: 0.5rem;
  font-size: 1.1rem;
}

.ranking-category strong {
  color: #ffd700;
}

.blurred-content {
  filter: blur(5px);
  user-select: none;
  pointer-events: none;
}

.result-section h2,
.result-section h3,
.result-section h4 {
  filter: blur(0);
  user-select: auto;
  pointer-events: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: linear-gradient(135deg, #000000, #8e2de2);
  padding: 2rem;
  border-radius: 20px;
  max-width: 500px;
  width: 90%;
  color: #ffffff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.payment-prompt {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
}

.payment-prompt button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.payment-prompt button:hover {
  background-color: #45a049;
}

.cta-section {
  background: inherit;
  padding: 4rem 2rem;
  text-align: center;
  color: #ffffff;
}

.cta-content {
  max-width: 800px;
  margin: 0 auto;
}

.cta-heading {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-family: 'Roboto', sans-serif;
}

.cta-subtext {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 30px;
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
}

.cta-section .cta-button-hero {
  margin-top: 1rem;
}